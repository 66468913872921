/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import RypxzCheck from "../../../common/zzjg/RypxzCheck";
import RypxzForm from "../../../common/zzjg/RypxzForm";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, unique, uniqueRypxz, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqRypxz",
  components: {
    LCFQheader,
    RypxzCheck,
    RypxzForm
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "人员培训总计划",
      showDataCG: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      resultFa: [],
      dataListIndex: [],
      tremListId: [],
      dataInfo: {},
      showBntData: "显示",
      indexNum: "",
      datas: {
        jhbh: '',
        jhmc: ''
      },
      pxxmVal: "",
      bmVal: "",
      cgsqrT: new Date()
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
    },
    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No;
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          this_.datas.jhbh = resData.jhbh;
          this_.datas.jhmc = resData.jhmc;
          resData.SUB_rypxjhnr.map((item, index) => {
            var addObj = {};
            //草稿回填，数据测试下。

            addObj.F_JHBH = item.jhbh;
            addObj.F_JHMC = item.jhmc;
            addObj.F_PXXM = item.pxxm;
            addObj.F_SSJHRQ = item.ssjhrq;
            addObj.F_PXDD = item.pxdd;
            addObj.F_SJRY_NAME = item.sjry;
            addObj.F_SKRY = item.skry;
            addObj.F_KHFS = item.khfs;
            addObj.F_ZZZ_NAME = item.zzz;
            this_.dataListIndex.push(addObj);
          });
        });
        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+instId;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        // })
      } else {
        this.getbh();
      }
    },
    getbh() {
      var this_ = this;
      var url = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqrypxzjh.do";
      this.$ajax.post(url).then(res => {
        // console.log(res.data.data);
        var arr = res.data.data;
        var date = new Date();
        var bh = 0;
        for (var i = 0; i < arr.length; i++) {
          if (arr.length != 0) {
            //合同创建日期
            var sj = arr[i].CREATE_TIME_;
            var sj1 = parseInt(sj);
            var date1 = new Date(sj1);
            if (date1.getFullYear() == date.getFullYear()) {
              var zh1 = arr[i].ZH;
              bh += parseInt(zh1);
            }
          } else {
            bh = 0;
          }
        }
        console.log(bh);
        bh = bh + 1;
        var year = date.getFullYear() + "";
        var year1 = year.substring(2);
        var jhbh = '';
        if (bh <= 9) {
          jhbh = "ZJH" + "-" + year1 + "-" + "00" + bh;
        } else if (99 > bh && bh >= 10) {
          jhbh = "ZJH" + "-" + year1 + "-" + "0" + bh;
        } else {
          jhbh = "ZJH" + "-" + year1 + "-" + bh;
        }
        this_.datas.jhbh = jhbh; //生成计划编号
      });
    },

    //添加列表项目
    addTerm() {
      this.tremListId = [];
      this.dataListIndex.map(item => {
        this.tremListId.push(item.ID_);
      });
      this.showListpage = true;
    },
    tremList(list) {
      // let listIndex = [];
      // this.dataListIndex.map((item)=>{
      //   listIndex.push(item);
      // })
      // list.map((item)=>{
      //   listIndex.push(item);
      // })
      // // this.dataListIndex = uniqueRypxz(listIndex);
      // this.dataListIndex = listIndex;
      // this.showListpage = false;

      let listIndex = [];
      this.dataListIndex.map(item => {
        listIndex.push(item);
      });
      list.map(item => {
        listIndex.push(item);
      });
      /**新增重复问题解决，过滤掉同对象*/
      var obj = {};
      this.dataListIndex = listIndex.reduce(function (item, next) {
        obj[next.ID_] ? '' : obj[next.ID_] = true && item.push(next);
        return item;
      }, []);
      this.showListpage = false;
    },
    pxxmName(value) {
      this.pxxmVal = value;
    },
    bmName(value) {
      this.bmVal = value;
    },
    addTermContent() {
      let addData = {
        F_JHBH: "",
        F_JHMC: "",
        F_PXXM: "",
        F_SSJHRQ: "",
        F_PXDD: "",
        F_SJRY_NAME: "",
        F_SKRY: "",
        F_KHFS: "",
        F_ZZZ_NAME: "",
        INST_STATUS_: ""
      };
      let rq = new Date();
      addData.F_SSJHRQ = format(rq);
      this.dataListIndex.push(addData);
    },
    //查看具体信息
    goodsInfo(item, index) {
      this.indexNum = index;
      this.dataInfo = item;
      this.showGinfo = true;
    },
    //完善信息
    tremListInfo(item) {
      this.dataListIndex[this.indexNum] = item;
      this.showGinfo = false;
    },
    //删除
    delectData() {
      let desc = function (a, b) {
        return b - a;
      };
      this.resultFa.sort(desc);
      for (var i = 0; i < this.resultFa.length; i++) {
        this.dataListIndex.splice(this.resultFa[i], 1);
      }
      this.$refs.checkboxGroup.toggleAll(false);
    },
    async upDatas() {
      let totalList = [];
      let formData = new FormData();
      formData.append("Q_F_PXXM_S_EQ", this.pxxmVal);
      formData.append("Q_F_BM_NAME_S_EQ", this.bmVal);
      let url = _baseUrl + `/dev/cus/customData/rypxjh_list/getData.do`;
      await this.$ajax.post(url, formData).then(res => {
        totalList = res.data.data;
      });
      totalList.map((item, index) => {
        item._id = index + 1;
        item._uid = index + 1;
      });
      let addObj = {};
      let addedList = [];
      this.dataListIndex.map((item, index) => {
        addObj = {};
        addObj.jhbh = item.F_JHBH;
        addObj.jhmc = item.F_JHMC;
        addObj.pxxm = item.F_PXXM;
        addObj.ssjhrq = item.F_SSJHRQ;
        addObj.pxdd = item.F_PXDD;
        addObj.sjry = item.F_SJRY_NAME;
        addObj.skry = item.F_SKRY;
        addObj.khfs = item.F_KHFS;
        addObj.zzz = item.F_ZZZ_NAME;
        addObj._id = totalList.length + index + 1;
        addObj._uid = totalList.length + index + 1;
        addObj._state = "added";
        addedList.push(addObj);
      });
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            jhbh: this.datas.jhbh,
            jhmc: this.datas.jhmc,
            Q_F_PXXM_S_EQ: this.pxxmVal,
            Q_F_BM_NAME_S_EQ: this.bmVal,
            //SUB_rypxjh_listGrid:totalList,
            SUB_rypxjhnr: addedList
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.datas.jhbh) || this.iN(this.datas.jhmc)) {
        return true;
      }
      return false;
    },
    async onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      if (this.dataListIndex.length == 0) {
        this.errorToast("启动失败！请至少添加一条计划", "1800");
        return;
      }
      var params = await this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    async onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = await this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};